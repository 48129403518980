import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const Section1 = require("../../../assets/img/blogs/blog_digital_1.png");
const Section2 = require("../../../assets/img/blogs/blog_digital_2.jpg");
const Section3 = require("../../../assets/img/blogs/blog_digital_3.gif");
const Section4 = require("../../../assets/img/blogs/blog_digital_4.jpg");
const Section5 = require("../../../assets/img/blogs/blog_digital_5.jpeg");
const Section6 = require("../../../assets/img/blogs/blog_digital_6.jpeg");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Reasons why digital transformations fails and how conversational ai chatbots with task automation can help"
        description="Learn how digital transformation fails, the challenges involved, and how to strategically go about it with chatbots and automation."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={Section1}
        schema={ldSchema}
        url={
          "https://medium.com/the-research-nest/reasons-why-digital-transformations-fail-and-how-ai-powered-chatbots-can-help-6e766a9754d9"
        }
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header  ">
                            Digital Workers to the rescue: Reasons why Digital
                            Transformations fail and how AI-Powered Chatbots
                            with Automated Workflows can help
                          </h1>
                        </div>
                      </div>
                      <img src={Section1} />
                      <div className="market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold mt-4">
                          What is HR workflow automation?
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Chances are, you have found yourself on this blog
                          because you’re a Business Owner, a CIO, or an IT
                          Manager, who’s looking toward digital transformation
                          to stay relevant with changing times. Can’t blame you
                          though.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          According to{" "}
                          <a href="https://insights.prophet.com/the-state-of-digital-transformation-2018-2019">
                            this
                          </a>{" "}
                          survey, market pressures are the leading drivers of
                          digital transformation as most efforts are spurred by
                          growth opportunities (51%) and increased competitive
                          pressure (41%). With high-profile data breach scandals
                          making daily headlines, new regulatory standards like
                          GDPR are also providing the impetus for organizations
                          to transform (38%).
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So, yeah, I get how you must feel.{" "}
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Hearing the buzzword “Digital Transformation” that
                          businesses nowadays use might make any Business Owner,
                          CIO or an IT Manager anxious and feel pressurized,
                          thinking about the time and effort it takes for proper
                          implementation as well as the budget constraints.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          No worries though. Once you clearly understand what
                          digital transformation is, why it fails for some
                          companies, the challenges involved, and how to
                          strategically go about it, digital transformation
                          becomes a piece of cake for you.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So without further ado, let’s get started by seeing
                          what digital transformation really means at an
                          organizational level.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          What is Digital Transformation?
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          According to{" "}
                          <a href="https://enterprisersproject.com/what-is-digital-transformation">
                            The Enterprisers Project,
                          </a>{" "}
                          Digital Transformation is the integration of digital
                          technology into all areas of a business, fundamentally
                          changing how you operate and deliver value to
                          customers. It’s also a cultural change that requires
                          organizations to continually challenge the status quo,
                          experiment, and get comfortable with failure.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now that we got that out of the way, let’s move on to
                          the reasons why some companies might fail at digital
                          transformation.{" "}
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Reasons Why Digital Transformations Fail
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          As humans, we like our routines, whether they are
                          boring or not. So our first response to any kind of
                          change will be, well, a straight-up “NO!” I’m no
                          exception, to be honest. But we all know that change
                          is the one thing that’s constant in this world and
                          embracing change will help us in the long run.
                          Evolution teaches us this. The animals that were able
                          to adapt and overcome environmental factors are the
                          ones we see today. I’m talking about us humans as
                          well. We were able to beat evolution at its own game,
                          so I’m sure we can go head-to-head with digital
                          transformation as well.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Here are some of the reasons companies might fail at
                          Digital Transformation so you can prepare yourself
                          better before you actually go and do it. Improvise,
                          Adapt, and Overcome, my friend.{" "}
                        </p>
                        <h6 className="font-section-sub-header-small">
                          1. When people aren’t on the same page, they aren’t on
                          the same page
                        </h6>
                        <img loading="lazy" src={Section2} />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          You might’ve heard that changes in an organization
                          should always start from the top level. That’s because
                          when the management is on the same page, it becomes
                          easier to get other employees on the same page. For
                          this, the management should and must agree on a
                          digitalization goal that would open up a sea of
                          opportunities for the organization to streamline
                          existing business processes — be it{" "}
                          <a href="https://workativ.com/">
                            internal IT support or HR{" "}
                          </a>{" "}
                          support offered to employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          After the top-level zeroes-out on a digitalization
                          goal, the next step would be to strategize on how to
                          introduce changes that’ll accomplish the goal without
                          any hiccups.{" "}
                        </p>
                        <h6 className="font-section-sub-header-small">
                          2. Unrealistic estimation of available technology
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          It is tempting to get excited while approaching new
                          ideas. And digital transformation is no exception.
                          Companies tend to get carried away while strategizing,
                          thinking that they just need to curate the current
                          technologies in their company for their digitalization
                          efforts. But sadly, that’s not the case most of the
                          time.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Not analyzing the capabilities available at a
                          company’s disposal to support scaling digitalization
                          efforts leads to the failure of the pilot. Yep. You
                          heard that right.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The remedy? Well, I would suggest you look outside
                          your company, probably toward B2B companies that offer
                          a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                            no-code platform
                          </a>{" "}
                          or tools that’ll transform the way your business
                          operates and keeps you ahead of your competitors, to
                          say the least.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are more reasons to ponder over to know what
                          should be done right to achieve digitalization goals.
                          But they aren’t as much important as the above two
                          reasons are, which, once you get a grip over, enables
                          you to go about digital transformation in the right
                          way.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To help you be more prepared before you actually start
                          with the digitalization efforts at your company, let’s
                          take a look at some of the challenges you might face
                          during the initial pilot.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          Challenges you might face during the Digital
                          Transformation journey
                        </h5>
                        <h5 className="font-section-sub-header-small">
                          1. Whosoever holds this hammer, if he be worthy, shall
                          possess the power of Thor
                        </h5>
                        <img loading="lazy" src={Section3} style="width:70%" />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          Having the right technology is great and all. But
                          having people who know what exactly should be done
                          with the technology becomes ever more vital for
                          organizational initiatives to thrive. And digital
                          transformation is no exception to this.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Businesses should be ready to hire skilled talents who
                          can lead the digitalization efforts internally across
                          verticals or upskill existing employees to be
                          digitally literate. It can also be beneficial for
                          companies to have business partners who take care of
                          digital transformation internally for them.
                        </p>
                        <h5 className="font-section-sub-header-smalL">
                          2. Lack of Overall Change Management Strategy
                        </h5>
                        <img loading="lazy" src={Section4} />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          <a href="https://www.servicedeskshow.com/feature/why-change-management-is-important-and-selecting-the-right-automation-tool-for-it-matters/">
                            Change Management is crucial for a business.
                          </a>{" "}
                          Therefore it is incumbent on a business to properly go
                          about Change Management with the right Change Manager
                          at the helm backed up by the Change Advisory Board
                          (CAB).
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Proper Change Management Strategy by the Change
                          Manager is essential to introduce changes at an
                          organizational level without eating up available
                          resources, time, or budget.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          3. Proper calculation of time frame for Digitalization
                          Initiatives
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          No one can really come up with the timescale for
                          digitalization initiatives. Literally no one. Because,
                          as you would know, digitalization is an ongoing thing
                          that companies will have to implement and continuously
                          improve upon, to keep up with today’s constantly
                          changing digital scenario.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          According to Mckinsey’s{" "}
                          <a href="https://www.mckinsey.com/industries/financial-services/our-insights/a-roadmap-for-a-digital-transformation">
                            roadmap
                          </a>{" "}
                          for digital transformation, it may take five years for
                          companies that may have outdated internal processes to
                          overhaul and streamline processes with digitalization.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So coming up with a realistic time frame when
                          strategizing digitalization is imperative to let
                          employees know how long it’ll actually take to realize
                          the digital transformation goals. This gives those
                          involved in digitalization to relentlessly put in the
                          effort and work towards the goal without getting
                          frustrated.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Putting a leash on budgets
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          According to <a href="https://www.idc.com/">IDC, </a>
                          digital transformation spending will grow to more than
                          53% of all information and communications technology
                          (ICT) investment by 2023, up from 36% today.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So when plenty of cash is flowing into digital
                          initiatives at large, it is natural for CIOs to expect
                          a substantial Return On Digital Investment (RODI). But
                          more often than not, the results tend to be quite
                          disappointing for CIOs. The primary reason:
                          unsuccessful efforts to scale digital innovations
                          beyond early pilot work.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Because of the mediocre RODI, CIOs tend to put a
                          restrain on the cash flow that’s going into
                          digitalization instead of tracking the efforts taken
                          for digital transformation and removing hinders then
                          and there.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          5. Nope, Never
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You may have already heard this. Employees refusing
                          digitalization efforts that include automating tasks
                          with Conversational AI that might be repetitive and
                          dull and causes attrition. That’s because employees
                          are afraid that AI and Automation will replace them at
                          their current jobs.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          The solution to this is obvious. CIOs and IT Managers
                          should come up with ways in which they can educate the
                          employees about the different technologies that’ll be
                          incorporated into their digitalization strategy and
                          how these technologies will prove to be a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                            companion to employees helping them with mundane
                            tasks
                          </a>{" "}
                          so that employees can upskill themselves by tacking
                          complex tasks at hand.
                        </p>

                        <iframe
                          src="https://www.youtube.com/embed/LHhlH2baBok?feature=oembed"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          title="video"
                          width="100%"
                          height="460"
                        />

                        <p class="font-section-normal-text line-height-2">
                          Hmm… that’s about it when it comes to challenges that
                          you might come face-to-face while starting out with
                          digital transformations inside your company.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen about the challenges, let’s see
                          where you can start with your digitalization efforts
                          and how it impacts the workplace for your employees at
                          scale.
                        </p>
                      </div>
                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          Where and how to start with digital transformation
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Most businesses concentrate on customer experience and
                          how they can streamline processes to meet customer
                          needs better. But what they forget is that just as
                          customer experience is important for a business to
                          meet its revenue goals, employee experience at the
                          workplace is equally important. Satisfied employees
                          turn into your brand ambassadors who truly make the
                          customer experience better.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So not to beat around the bush, here are the two
                          low-hanging fruits where you can start your
                          digitalization efforts.
                        </p>
                        <h5 className="font-section-sub-header-smalL">
                          1. Employee Experience (EX)
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Workplace IT support or Employee IT support can be the
                          first place you look at to improve employee experience
                          with digitalization.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Automating banal, repetitive tasks like password
                          reset, account unlocks, etc., and quickly delivering
                          the automated solution via AI-powered conversational
                          digital assistants on communication channels like
                          Slack or Microsoft Teams helps employees to self-serve
                          and resolve their IT issues themselves, on-the-go.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            {" "}
                            Automating IT support workflows{" "}
                          </a>
                          leaves room for internal service desk agents to focus
                          on taking up complex, high-value problems that truly
                          need a human touch. That’s what service desk agents
                          also want ¯\_(ツ)_/¯ Because, according to{" "}
                          <a href="https://www.gallup.com/workplace/238085/state-american-workplace-report-2017.aspx">
                            Gallup,
                          </a>{" "}
                          60% of employees say “the ability to do what they do
                          best” in a role is “very important” to them. What more
                          can an employee ask for, right?
                        </p>
                        <h5 className="font-section-sub-header-small">
                          2. HR Experience
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The work of an HR is no joke. Constant screening of
                          candidates, taking care of onboarding/offboarding
                          processes for employees, addressing employee
                          grievances,… phew, what a work that must be.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The least you can do for your HR is to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            automate some of the repetitive HR processes
                          </a>{" "}
                          like onboarding/offboarding employees so that they can
                          take care of these through AI-powered chatbots on
                          communication hubs like Slack or Microsoft Teams. This
                          helps HRs to focus on taking care of employee
                          grievances that truly require
                        </p>

                        <h5 className="font-section-sub-header-small-bold">
                          Enter Digital Workers.
                        </h5>
                        <img loading="lazy" src={Section5} />
                        <p class="font-section-normal-text  mt-4 line-height-2">
                          Think of digital workers like intelligent chatbot who
                          can deliver resolutions to common workplace IT issues
                          like password resets, account unlocks, etc., through
                          <a href="https://workativ.com/assistant/workflow-automation">
                            {" "}
                            conversational AI and automated workflows
                          </a>{" "}
                          on a chat hub like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                            Slack or Microsoft Teams.
                          </a>{" "}
                          These digital workers can be highly domain-specific
                          like IT chatbots or HR chatbots.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Digital Transformation in a company almost always
                          starts with integrating digital workers with business
                          processes, turbo-charging the processes with
                          Artificial Intelligence and Automation that digital
                          workers incorporate.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are world-class platforms out there to build
                          domain-specific digital workers but the question comes
                          down to whether they offer world-class solutions or
                          not. It is important to properly analyze business
                          needs and choose a platform based on those needs
                          instead of going full-on out for the platform with the
                          most features.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Here are some aspects that you should consider before
                          going for a digital worker/chatbot-building platform
                          with AI and Automation.{" "}
                        </p>
                        <h5 className="font-section-sub-header-small">
                          1. A no-code platform with Intuitive UI
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Empowering frontline workers like service desk or help
                          desk agents to create and deploy digital workers that
                          perform certain tasks for them should be your main
                          goal while opting for digital transformation. That’s
                          because service desk agents very well know what the
                          common IT issues that employees call them for and how
                          employees want those issues sorted out for them.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          For internal IT agents to create digital workers that
                          deliver automated resolutions to employees at scale on
                          their favorite communication hubs like Slack or
                          Microsoft Teams, the digital worker/chatbot-building
                          platform should require no coding from the agents’
                          side and one-click integration with chat platforms
                          like Slack or Microsoft Teams. Because service desk
                          agents may not be experts at coding languages, unlike
                          developers.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          2. Not only Automated Workflows, but Conversational AI
                          as well.{" "}
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Not only automating workflows, but the platform for
                          building digital workers should have incorporated
                          AI-powered conversation flow designers as well. Sadly,
                          nowadays in the market, a chatbot-building platform
                          either has a builder for setting up automations or it
                          may have only a designer for conversational flows and
                          not both.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          3. Feature-rich User Insights
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Knowing what employees want empowers CIOs and IT
                          Managers to better streamline IT processes for them by
                          setting up digital workers that talk to them in a way
                          they would appreciate and resolve IT issues for them
                          in an instant, real-time, with the power of AI and
                          Automation. For this, it is essential that the
                          platform incorporates an Analytics and Logs feature
                          that’ll chew-down all the historical data from the
                          digital workers’ conversations with different
                          employees and show you easily understandable,
                          actionable insights.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          4. Takes care of itself
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Over a period of time, the platform should train
                          digital workers based on the digital workers’ previous
                          conversations with different employees to
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                            {" "}
                            better recognize employee intent
                          </a>{" "}
                          and to extract required data from an employees’ query
                          using Natural Language Understanding.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen about the challenges, let’s see
                          where you can start with your digitalization efforts
                          and how it impacts the workplace for your employees at
                          scale.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          5. Gentle on your budget
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          There are many Platform-As-A-Service offerings out
                          there in the market today, but a platform that takes
                          care of all your business’ digitalization needs
                          without requiring a huge budget for it is crucial for
                          better RODI. Maybe a platform that offers
                          monthly/annual subscription plans for the only the
                          features that you’ll ever need, like a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/pricing">
                            pay as you go platform.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          And that wraps up our discussion on what digital
                          transformation is, why it fails for some companies,
                          the challenges involved, and how to strategically go
                          about it, so you can handle digitalization with ease.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To top it off with a famous quote we are all familiar
                          with,
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          “It is not the most intellectual of the species that
                          survives; it is not the strongest that survives, but
                          the species that survives is the one that is able best
                          to adapt and adjust to the changing environment in
                          which it finds itself.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          — Leon C. Megginson, 1963.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Yeah, you read that right. It was in a speech by
                          Louisiana State University business professor named
                          Leon C. Megginson at the convention of the
                          Southwestern Social Science Association. Not Charles
                          Darwin.
                        </p>
                        <img loading="lazy" src={Section6} />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          Now that you know how to go about digital
                          transformation, I would recommend that you check out
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            &nbsp;this{" "}
                          </a>
                          blog on how to transform your Slack channel into an
                          AI-driven digital help desk for your employees. If you
                          are a Microsoft Teams user, check out{" "}
                          <a href="https://medium.com/workativ-ai-automation/4-little-known-reasons-why-you-should-use-microsoft-teams-as-a-digital-help-desk-for-employee-fc0494091366">
                            this{" "}
                          </a>
                          blog. Or, if you’re confused as to whether to go with
                          Slack or Microsoft Teams as your business’ primary
                          communication hub, then check out this blog.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you’re interested in incorporating an AI-powered
                          platform to build digital workers for your workplace
                          IT support that delivers resolutions to employees
                          conversationally with automated workflows on Slack or
                          Microsoft Teams, then look no further. Hit us up at
                          sales@workativ.com and we’d be more than happy to give
                          you a demo of our product.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers">
                                Digital Workers to the rescue: Reasons why
                                Digital Transformations fail and how AI-Powered
                                Chatbots with Automated Workflows can help
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                {" "}
                                TChatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                                Top 8 Human Resource Tasks to Automate for
                                better Employee Experience
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
